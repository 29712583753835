export const datas_auffargis_2023 = {
    documents: [
      {  
        type: 'pdf', 
        path: 'assets/pdf/2023/auffargis/2023.pdf', 
        name: 'Calendrier 2023'
      },
      {  
        type: 'jpg', 
        path: 'assets/pdf/2023/auffargis/2023.jpg', 
        name: 'Calendrier 2023'
      }
    ],
    collect_days: {
      "Toute zone": {
        "divers": {
          "value": "Tous les Mercredis",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Un mardi sur 2",
          "more": undefined,
          "days": [
            "2023-01-10",
            "2023-01-24",
            "2023-02-07",
            "2023-02-21",
            "2023-02-07",
            "2023-02-21",
            "2023-04-04",
            "2023-04-18",
            "2023-05-02",
            "2023-05-16",
            "2023-05-30",
            "2023-06-13",
            "2023-06-27",
            "2023-07-11",
            "2023-07-25",
            "2023-08-08",
            "2023-08-22",
            "2023-09-05",
            "2023-09-19",
            "2023-10-03",
            "2023-10-17",
            "2023-10-31",
            "2023-11-14",
            "2023-11-28",
            "2023-12-12",
            "2023-12-26"
          ]
        },
        "verre": {
          "value": "Un mardi sur 6",
          "more": undefined,
          "days": [
            "2023-02-07",
            "2023-03-21",
            "2023-05-02",
            "2023-06-13",
            "2023-07-25",
            "2023-09-05",
            "2023-10-17",
            "2023-11-28"
          ]
        },
        "vegetaux": {
          "value": undefined,
          "more": undefined,
          "days": [
          ]
        }
      }
    }
  };