import {INFOS,DATAS,LASTINFO} from   '../assets/json/DATAS';

import S_Utils  from "./utils";

interface I_LastInfo {
  id: string;
  title: string;
  text: string;
}
type T_LastInfo = I_LastInfo;

interface I_Infos {
  message: string;
  news: object[];
}
interface I_Data_City_Year_Days {
  [key: string]: object;
};
interface I_Data_City_Year {
  documents: object;
  collect_days: I_Data_City_Year_Days;
};
interface I_Data_City_Years {
  [key: string]: I_Data_City_Year;
};
interface I_Data_City {
  id: string;
  label: string;
  infos: undefined,
  zones: string[];
  datas: I_Data_City_Years
}
interface I_Datas {
  [key: string]: I_Data_City;
  // 'leperrayenyvelines'?: I_Data_City;
  // 'auffargis'?: I_Data_City;
  // 'lesbreviaires'?: I_Data_City;
  // 'lesessartsleroi'?: I_Data_City;
}
interface I_S_Datas {
  STORAGE_KEY_CITYID : string;
  STORAGE_KEY_ZONEID : string;
  STORAGE_KEY_INFOREAD : string;
  infos?: I_Infos;
  datas: I_Datas;
  lastInfo?: I_LastInfo;
  currentCity: string;
  currentZone: string;
  currentYear: string;
  currentInfoRead?: string;
  fetchAll: Function;
  getDatas: Function;
  addNextValues: Function;
  selectCity: Function;
  selectZone: Function;
  setInfoRead: Function;
}

let S_Datas : I_S_Datas = {
  STORAGE_KEY_CITYID : 'poubelles_city_id',
  STORAGE_KEY_ZONEID : 'poubelles_zone_id',
  STORAGE_KEY_INFOREAD : 'poubelles_inforead',

  infos: undefined,
  datas: {},
  lastInfo: undefined,

  currentCity: 'leperrayenyvelines',
  currentZone: 'Zone 1',
  currentYear: '2023',
  currentInfoRead: undefined,


  fetchAll: function(){
    S_Datas.infos = INFOS;
    S_Datas.datas = DATAS;
    S_Datas.lastInfo = LASTINFO;
    S_Datas.currentCity = 'leperrayenyvelines';
    let cityID = window.localStorage[S_Datas.STORAGE_KEY_CITYID];
    if(cityID){
      if(Object.keys(S_Datas.datas).indexOf(cityID) !== -1)
        S_Datas.currentCity = cityID;
    }
    S_Datas.currentZone = 'Zone 1';
    let zone = window.localStorage[S_Datas.STORAGE_KEY_ZONEID];
    if(zone){
      if(S_Datas.datas[S_Datas.currentCity].zones.indexOf(zone) !== -1)
        S_Datas.currentZone = zone;
    }
    S_Datas.currentYear = '2023';
    let infoRead = window.localStorage[S_Datas.STORAGE_KEY_INFOREAD];
    S_Datas.currentInfoRead = infoRead;

    console.log('Fetch datas : ',S_Datas.datas);
    console.log('Current location : ',S_Datas.currentCity, S_Datas.currentZone);
    console.log('Current year : ',S_Datas.currentYear);
    console.log('Current info read : ',S_Datas.currentInfoRead);
  },

  // renvoie les datas de la ville / zone selectionnee, pour l'annee en cours
  getDatas: function(){
    if(!S_Datas.currentCity){
      console.log('Cannot get datas with empty currentCity.');
      return {
        id: undefined,
        label: undefined,
        zones: [],
        infos: undefined,
        documents: [],
        days: {}
      }
    }
    if(!S_Datas.datas[S_Datas.currentCity]){
      console.log('No datas found for %s',S_Datas.currentCity);
      return {
        id: undefined,
        label: undefined,
        zones: [],
        infos: undefined,
        documents: [],
        days: {}
      }
    }
    
    let datas = S_Datas.datas[S_Datas.currentCity];

    if(!S_Datas.currentYear){
      console.log('Cannot get datas with empty currentYear.');
      return {
        id: datas.id,
        label: datas.label,
        zones: datas.zones,
        infos: datas.infos,
        documents: [],
        days: {}
      };
    }
    if(!datas.datas[S_Datas.currentYear]){
      console.log('No datas found for %s for year %s',S_Datas.currentCity,S_Datas.currentYear);
      return {
        id: datas.id,
        label: datas.label,
        zones: datas.zones,
        infos: datas.infos,
        documents: [],
        days: {}
      }
    }

    let documents = datas.datas[S_Datas.currentYear].documents;
    let collect_days = datas.datas[S_Datas.currentYear].collect_days;

    if(!S_Datas.currentZone){
      console.log('Cannot get datas with empty currentZone.');
      return {
        id: datas.id,
        label: datas.label,
        zones: datas.zones,
        infos: datas.infos,
        documents: documents,
        days: {}
      };
    }
    if(!collect_days[S_Datas.currentZone]){
      console.log('No datas found for %s for year %s and zone %s',S_Datas.currentCity,S_Datas.currentYear,S_Datas.currentZone);
      return {
        id: datas.id,
        label: datas.label,
        zones: datas.zones,
        infos: datas.infos,
        documents: documents,
        days: {}
      }
    }

    let collect_days_of_year = collect_days[S_Datas.currentZone];

    return {
      id: datas.id,
      label: datas.label,
      zones: datas.zones,
      infos: datas.infos,
      documents: documents,
      collect_days: collect_days_of_year
    };
  },

  addNextValues: function(collect_days_of_year: any){
    if(!collect_days_of_year)
      return {};

    let currentDate = S_Utils.getCurrentDate();

    if(collect_days_of_year.plastiques && collect_days_of_year.plastiques.days){
      if(collect_days_of_year.plastiques.days==='auto'){
        collect_days_of_year.plastiques.hasnext = true;
      }else{
        for(var i = 0; i < collect_days_of_year.plastiques.days.length; i++){
          var nextDate = S_Utils.getNextDate(collect_days_of_year.plastiques.days[i]);
          if(nextDate.getTime() >= currentDate.getTime()){
            collect_days_of_year.plastiques.date = S_Utils.formatDate(nextDate);
            collect_days_of_year.plastiques.alert = S_Utils.formatAlert(currentDate, nextDate);
            collect_days_of_year.plastiques.next = S_Utils.formatLabel(currentDate, nextDate);
            collect_days_of_year.plastiques.hasnext = true;
            break;
          }
        }
      }
    }

    if(collect_days_of_year.verre && collect_days_of_year.verre.days){
      if(collect_days_of_year.verre.days==='auto'){
        collect_days_of_year.verre.hasnext = true;
      }else{
        for(var i = 0; i < collect_days_of_year.verre.days.length; i++){
          var nextDate = S_Utils.getNextDate(collect_days_of_year.verre.days[i]);
          if(nextDate.getTime() >= currentDate.getTime()){
            collect_days_of_year.verre.date = S_Utils.formatDate(nextDate);
            collect_days_of_year.verre.alert = S_Utils.formatAlert(currentDate, nextDate);
            collect_days_of_year.verre.next = S_Utils.formatLabel(currentDate, nextDate);
            collect_days_of_year.verre.hasnext = true;
            break;
          }
        }
      }
    }

    if(collect_days_of_year.vegetaux && collect_days_of_year.vegetaux.days){
      if(collect_days_of_year.vegetaux.days==='auto'){
        collect_days_of_year.vegetaux.hasnext = true;
      }else{
        for(var i = 0; i < collect_days_of_year.vegetaux.days.length; i++){
          var nextDate = S_Utils.getNextDate(collect_days_of_year.vegetaux.days[i]);
          if(nextDate.getTime() >= currentDate.getTime()){
            collect_days_of_year.vegetaux.date = S_Utils.formatDate(nextDate);
            collect_days_of_year.vegetaux.alert = S_Utils.formatAlert(currentDate, nextDate);
            collect_days_of_year.vegetaux.next = S_Utils.formatLabel(currentDate, nextDate);
            collect_days_of_year.vegetaux.hasnext = true;
            break;
          }
        }
      }
    }
    console.log('Update dats to',collect_days_of_year);

    return collect_days_of_year;
  },


  selectCity: function(cityID: string){
    S_Datas.currentCity = cityID;
    window.localStorage[S_Datas.STORAGE_KEY_CITYID] = S_Datas.currentCity;
  },

  selectZone: function(zone: string){
    S_Datas.currentZone = zone;
    window.localStorage[S_Datas.STORAGE_KEY_ZONEID] = S_Datas.currentZone;
  },

  setInfoRead: function(infoID: string|undefined){
    S_Datas.currentInfoRead = infoID;
    window.localStorage[S_Datas.STORAGE_KEY_INFOREAD] = infoID;
  }
  
};
export default S_Datas;
export type {T_LastInfo};