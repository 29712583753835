export const datas_lesbreviaires_2023 = {
    documents: [
      {  
        type: 'pdf', 
        path: 'assets/pdf/2023/les-breviaires/2023.pdf', 
        name: 'Calendrier 2023'
      },
      {  
        type: 'jpg', 
        path: 'assets/pdf/2023/les-breviaires/2023-1.jpg', 
        name: 'Calendrier 2023'
      },
      {  
        type: 'jpg', 
        path: 'assets/pdf/2023/les-breviaires/2023-2.jpg', 
        name: 'Page 2 2023'
      }
    ],
    collect_days: {
      "Toute zone": {
        "divers": {
          "value": "Tous les Mercredis",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Un lundi sur 2",
          "more": undefined,
          "days": [
            "2023-01-09",
            "2023-01-23",
            "2023-02-06",
            "2023-02-20",
            "2023-03-06",
            "2023-03-20",
            "2023-04-03",
            "2023-04-17",
            "2023-04-29",
            "2023-05-15",
            "2023-05-29",
            "2023-06-12",
            "2023-06-26",
            "2023-07-10",
            "2023-07-24",
            "2023-08-07",
            "2023-08-21",
            "2023-09-04",
            "2023-09-18",
            "2023-10-02",
            "2023-10-16",
            "2023-10-30",
            "2023-11-13",
            "2023-11-27",
            "2023-12-11",
            "2023-12-23"
          ]
        },
        "verre": {
          "value": "Un lundi sur 6",
          "more": undefined,
          "days": [
            "2023-01-09",
            "2023-02-20",
            "2023-04-03",
            "2023-05-15",
            "2023-06-26",
            "2023-08-07",
            "2023-09-18",
            "2023-10-30",
            "2023-12-11"
          ]
        },
        "vegetaux": {
          "value": undefined,
          "more": undefined,
          "days": [
          ]
        }
      }
    }
  };