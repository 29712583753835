import {datas_leperrayenyvelines} from   './DATAS-leperrayenyvelines';
import {datas_lesessartsleroi} from   './DATAS-lesessartsleroi';
import {datas_auffargis} from   './DATAS-auffargis';
import {datas_lesbreviaires} from   './DATAS-lesbreviaires';

export const DATAS = {
  'leperrayenyvelines' : {
    id: 'leperrayenyvelines',
    label: 'Le Perray-en-Yvelines',
    infos: undefined,
    zones: [
      'Zone 1',
      'Zone 2',
      'Collectif 1',
      'Collectif 2'
    ],
    datas: datas_leperrayenyvelines
  },
  'auffargis' : {
    id: 'auffargis',
    label: 'Auffargis',
    infos: undefined,
    zones: [
      'Toute zone'
    ],
    datas: datas_auffargis
  },
  'lesbreviaires' : {
    id: 'lesbreviaires',
    label: 'Les Bréviaires',
    infos: undefined,
    zones: [
      'Toute zone'
    ],
    datas: datas_lesbreviaires
  },
  'lesessartsleroi' : {
    id: 'lesessartsleroi',
    label: 'Les Essarts-le-roi',
    infos: undefined,
    zones: [
      'Zone 1',
      'Zone 2',
      'Collectif 1',
      'Collectif 2'
    ],
    datas: datas_lesessartsleroi
  }
};
export const INFOS = {
  message: '',
  news: [
    
  ]
};
export const LASTINFO = {
  id : '2024-01-11--01',
  // title : 'Test info',
  // text : 'Voici le texte <a href="https://wwww.google.com" target="_blank">avec un lien</a> <ul><li>test puce</li></ul>'
  title : 'Bonne année !',
  text : 'Je ne vous oublie pas! <br/>Je suis en train mettre à jour l\'appli avec les nouvelles données 2024.<br/><br/><u>NOUVEAUTE</u> :<br/>Un bouton en bas à droite vous permet de lire la dernière news en ligne.'
};