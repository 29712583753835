import React from 'react';
import PropTypes from 'prop-types';

import {T_LastInfo} from '../services/datas';
import './newsBox.scss';
 
NewsBox.propTypes = {
  lastInfo: PropTypes.object,
};
function NewsBox({lastInfo} : {lastInfo:T_LastInfo}) {
  return (
    <div className="newsBox">
      <div className="newsBoxID">{lastInfo.id}</div>
      <h3 dangerouslySetInnerHTML={{__html: lastInfo.title}}></h3>
      <div className="newsBoxText" dangerouslySetInnerHTML={{__html: lastInfo.text}}></div>
    </div>
  );
}
export default NewsBox;