import React from 'react';
import { Link } from 'react-router-dom';


import './menu.scss';
 
export default function Menu() {
  return (
    <div className="menu">
      <Link to={'/'} className="homeButton"></Link>
      <Link to={'/documents'} className="docsButton">DOCS</Link>
      <Link to={'/about'} className="helpButton">?</Link>
    </div>
  );
}