import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Home      from "./pages/home";
import Documents from "./pages/documents";
import About     from "./pages/about";

import S_Datas   from "./services/datas";

import './App.scss';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/documents",
    element: <Documents />,
  },
]);



function App() {

  S_Datas.fetchAll();

  return (
    <div className="App background">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
