import React, {useState,useEffect} from 'react';
import MetaTags from 'react-meta-tags';

import Menu     from "../components/menu";
import Version  from "../components/version";
import Bubble  from "../components/Bubble";
import NewsBox  from "../components/NewsBox";

import S_Utils  from "../services/utils";
import S_Datas  from "../services/datas";
 
import './home.scss';

export default function Home() {
  const [ modalOpened, setModalOpened ] = useState<boolean>(false); // modal de selection de la zone
  const [ currentCityID, setCurrentCityID ] = useState<string|undefined>(undefined);
  const [ currentZone, setCurrentZone ] = useState<string|undefined>(undefined);
  const [ triggerAnimEnter, setTriggerAnimEnter ] = useState(false);
  const [ triggerAnim, setTriggerAnim ] = useState(false);
  const [ modalInfoOpened, setModalInfoOpened ] = useState<boolean>(false); // modal d'infos

  let today = S_Utils.getToday();

  let datas = S_Datas.getDatas();
  let city = datas.label;
  let zone = S_Datas.currentZone;
  let days = datas.collect_days;
  let lastInfo = S_Datas.lastInfo;

  days = S_Datas.addNextValues(days);

  let citiesID = S_Datas.datas ? Object.keys(S_Datas.datas) : [];
  let zones = S_Datas.datas && S_Datas.currentCity ? S_Datas.datas[S_Datas.currentCity].zones : [];
  let infoRead = S_Datas.currentInfoRead;

  // TODO : charger infos generales en fetch get, pour que si les gens ont pas mis a jour, ils aient l'infos qd meme

  useEffect(() => {
    setCurrentCityID(S_Datas.currentCity);
    setCurrentZone(S_Datas.currentZone);

    setTimeout(function(){
      setTriggerAnimEnter(true);
    },300);
  });
  
  function openModal(_e: any){
    setModalOpened(true);
  }
  function closeModal(_e: any){
    setModalOpened(false);
  }
  function selectCity(cityID: string){
    let newZones = S_Datas.datas[cityID].zones;
    S_Datas.selectCity(cityID);
    setCurrentCityID(cityID);
    S_Datas.selectZone(newZones[0]);
    setCurrentZone(newZones[0]);
    setTriggerAnim(true);
    setTimeout(function(){
      setTriggerAnim(false);
    },600);
  }
  function selectZone(zone: string){
    S_Datas.selectZone(zone);
    setCurrentZone(zone);
    setModalOpened(false);
    setTriggerAnim(true);
    setTimeout(function(){
      setTriggerAnim(false);
    },600);
  }
  function openModalInfo(_e: any){
    setModalInfoOpened(true);
    S_Datas.setInfoRead(lastInfo?.id);
  }
  function closeModalInfo(_e: any){
    setModalInfoOpened(false);
  }

  return (
    <div className="home">

      <MetaTags>
        <title>Prochaines dates de collecte des déchets</title>
        <meta name="description" content="Les prochaines dates de collecte des déchets au Perray-en-Yvelines et aux Essarts-le-roi (78)" />
        <meta property="og:title" content="Prochaines dates de collecte des déchets" />
      </MetaTags>

      <Menu />

      <div className="dates">
        <div className="titles">
          <h1>POUBELLES</h1>
          <div className="today">Nous sommes<br/>{ today }</div>
          { S_Datas.infos?.message ? <div className="infos-generales">{ S_Datas.infos.message }</div> : null }
          <div className="targetButton" onClick={openModal}>
            <span>{ city }</span>
            <span>{ zone }</span>
          </div>
          { datas.infos ? <div className="infos">{ datas.infos }</div> : null }
        </div>
        <div className="nextin columns">

          { days && days.divers ? (
          <div className={'date date-divers soon ' + (triggerAnim ? 'scale-active' : '') + (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/bin.svg'} alt="" /> Divers</div>
            { days.divers.value ? <div className="value">{ days.divers.value }</div> : null }
            { days.divers.next ? <div className="value">{ days.divers.next }</div> : null }
            { days.divers.date ? <div className="value-exact">Le { days.divers.date }</div> : null }
            { days.divers.more ? <div className="value-more">{ days.divers.more }</div> : null }
          </div>
          ) : (
          <div className={'date date-divers disabled ' + days.divers.alert + (triggerAnim ? ' scale-active' : '') + (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/bin.svg'} alt="" /> Divers</div>
            <div className="value-missing">Nous n'avons pas encore les prochaines dates</div>
          </div>
          ) }

          { days && days.plastiques && days.plastiques.hasnext ? (
          <div className={'date date-plastiques ' + days.plastiques.alert + (triggerAnim ? ' scale-active' : '')+ (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/garbage'+(days.plastiques.alert==='imminent'?'-red':'')+'.svg'} alt="" /> Plastiques / Papiers</div>
            { days.plastiques.value ? <div className="value">{ days.plastiques.value }</div> : null }
            { days.plastiques.next ? <div className="value">{ days.plastiques.next }</div> : null }
            { days.plastiques.date ? <div className="value-exact">Le { days.plastiques.date }</div> : null }
            { days.plastiques.more ? <div className="value-more">{ days.plastiques.more }</div> : null }
          </div>
          ) : (
          <div className={'date date-plastiques disabled ' + days.plastiques.alert + (triggerAnim ? ' scale-active' : '')+ (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/garbage.svg'} alt="" /> Plastiques / Papiers</div>
            <div className="value-missing">Nous n'avons pas encore les prochaines dates</div>
          </div>
          ) }

          { days && days.verre && days.verre.hasnext ? (
          <div className={'date date-verre ' + days.verre.alert + (triggerAnim ? ' scale-active' : '')+ (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/glass-container'+(days.verre.alert==='imminent'?'-red':'')+'.svg'} alt="" /> Verre</div>
            { days.verre.value ? <div className="value">{ days.verre.value }</div> : null }
            { days.verre.next ? <div className="value">{ days.verre.next }</div> : null }
            { days.verre.date ? <div className="value-exact">Le { days.verre.date }</div> : null }
            { days.verre.more ? <div className="value-more">{ days.verre.more }</div> : null }
          </div>
          ) : (
          <div className={'date date-verre disabled ' + days.verre.alert + (triggerAnim ? ' scale-active' : '')+ (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/glass-container.svg'} alt="" /> Verre</div>
            <div className="value-missing">Nous n'avons pas encore les prochaines dates</div>
          </div>
          ) }

          { days && days.vegetaux && days.vegetaux.hasnext ? (
          <div className={'date date-vegetaux ' + days.vegetaux.alert + (triggerAnim ? ' scale-active' : '')+ (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/recycle-bin'+(days.vegetaux.alert==='imminent'?'-red':'')+'.svg'} alt="" /> V&eacute;g&eacute;taux</div>
            { days.vegetaux.value ? <div className="value">{ days.vegetaux.value }</div> : null }
            { days.vegetaux.next ? <div className="value">{ days.vegetaux.next }</div> : null }
            { days.vegetaux.date ? <div className="value-exact">Le { days.vegetaux.date }</div> : null }
            { days.vegetaux.more ? <div className="value-more">{ days.vegetaux.more }</div> : null }
          </div>
          ) : (
          <div className={'date date-vegetaux disabled ' + days.vegetaux.alert + (triggerAnim ? ' scale-active' : '')+ (triggerAnimEnter ? ' scale-enter' : '')}>
            <div className="label"><img src={'../assets/images/recycle-bin.svg'} alt="" /> V&eacute;g&eacute;taux</div>
            <div className="value-missing">Nous n'avons pas encore les prochaines dates</div>
          </div>
          ) }

        </div>
      </div>

      <Version />

      <Bubble active={lastInfo!==undefined && lastInfo.id !== infoRead} onClick={openModalInfo}/>

      { modalOpened ? (
      <div className="modal">
        <div className="modal-overlay"></div>
        <div className="modal-card">
          <h2>Ma ville, ma zone</h2>
          <div className="explain"><i>Se reporter &agrave; la carte</i></div>
          <div className="fields">
            <div className="cities">
              { citiesID.map((cityID,index) => {
                return (
                  <div key={index} className={'cityModalButton ' + (currentCityID === cityID ? 'selected' : '')} onClick={(e) => selectCity(cityID)}>{ S_Datas.datas[cityID].label }</div>
                );
              })}
            </div>
            <div>-</div>
            <div className="zones">
              { zones.map((zone: any, index: number) => {
                return (
                  <div key={index} className={'zoneModalButton ' + (currentZone === zone ? 'selected' : '')} onClick={(e) => selectZone(zone)}>{ zone }</div>
                );
              })}
            </div>
          </div>
          <div className="buttons">
            <div className="closeModalButton" onClick={closeModal}>OK</div>
          </div>
        </div>
      </div>
      ) : null }

    { modalInfoOpened ? (
      <div className="modal">
        <div className="modal-overlay"></div>
        <div className="modal-card">
          <h2>Dernière news</h2>
          {lastInfo ? <NewsBox lastInfo={lastInfo} /> : null}
          <div className="buttons">
            <div className="closeModalButton" onClick={closeModalInfo}>OK</div>
          </div>
        </div>
      </div>
      ) : null }
    </div>
  );
}