import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
 
import Menu  from "../components/menu";
import Version  from "../components/version";

import S_Datas  from "../services/datas";

import './documents.scss';

export default function Documents() {

  let datas = S_Datas.getDatas();
  let docs = datas.documents;


  return (
    <div className="docs">

      <MetaTags>
        <title>Fiches SICTOM calendriers de collecte</title>
        <meta property="og:title" content="Fiches SICTOM calendriers de collecte" />
      </MetaTags>

      <Menu />

      <div className="documents">
        <div className="titles">
          <h1>Documents</h1>
        </div>
        { docs && docs.length>0 ? (
        <div className="documents-list">
          { docs.map((doc: any, index: number) => {
            if(doc.type !== 'jpg')
              return null;
            return (
              <div key={index}>
                <div>
                  <div className="document">
                    <span className="name">{ doc.name }</span>
                    <span className="download"><Link to={doc.path} download title="Télécharger"></Link></span>
                  </div>
                  <img src={doc.path} alt={doc.name}/>
                </div>
              </div>
            );
          })}
        </div>
        ) : (
        <div className="empty-list">
        Aucun document trouvé.
        </div>
        )}
      </div>

      <Version />

    </div>
  );
}