import React from 'react';
import PropTypes from 'prop-types';

import './bubble.scss';
 
Bubble.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
function Bubble({active,onClick} : {active:boolean,onClick:Function}) {
  function handleClick(_e:any) {
    onClick();
  }
  return (
    <div className={'bubble' + (active ? ' active':'')} onClick={handleClick}>
      <div className="bubbleButton">✉</div>
    </div>
  );
}
export default Bubble;