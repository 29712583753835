export const datas_lesessartsleroi_2023 = {
    documents: [
      {  
        type: 'pdf', 
        path: 'assets/pdf/2023/les-essarts-le-roi/2023.pdf', 
        name: 'Calendrier 2023'
      },
      {  
        type: 'jpg', 
        path: 'assets/pdf/2023/les-essarts-le-roi/2023-1.png', 
        name: 'Calendrier 2023'
      },
      {  
        type: 'jpg', 
        path: 'assets/pdf/2023/les-essarts-le-roi/2023-2.png', 
        name: 'Zones 2023'
      },
      {  
        type: 'pdf', 
        path: 'assets/pdf/2023/les-essarts-le-roi/2023-vegetaux.pdf', 
        name: 'Calendrier 2023 végétaux'
      },
      { 
        type: 'jpg',
        path: 'assets/pdf/2023/les-essarts-le-roi/2023-vegetaux-1.png', 
        name: 'Calendrier 2023 végétaux calendrier'
      },
      { 
        type: 'jpg',
        path: 'assets/pdf/2023/les-essarts-le-roi/2023-vegetaux-2.png', 
        name: 'Calendrier 2023 végétaux zones'
      }
    ],
    collect_days: {
      "Zone 1": {
        "divers": {
          "value": "Tous les Jeudis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Un jeudi sur 2",
          "more": undefined,
          "days": [
            "2023-01-05",
            "2023-01-19",
            "2023-02-02",
            "2023-02-16",
            "2023-03-02",
            "2023-03-16",
            "2023-03-30",
            "2023-04-13",
            "2023-04-27",
            "2023-05-11",
            "2023-05-25",
            "2023-06-08",
            "2023-06-22",
            "2023-07-06",
            "2023-07-20",
            "2023-08-03",
            "2023-08-17",
            "2023-08-31",
            "2023-09-14",
            "2023-09-28",
            "2023-10-12",
            "2023-10-26",
            "2023-11-09",
            "2023-11-23",
            "2023-12-07",
            "2023-12-21"
          ]
        },
        "verre": {
          "value": "Un mercredi sur 6",
          "more": undefined,
          "days": [
            "2023-02-01",
            "2023-03-15",
            "2023-04-26",
            "2023-06-07",
            "2023-07-19",
            "2023-08-30",
            "2023-10-11",
            "2023-11-22"
          ]
        },
        "vegetaux": {
          "value": "Les lundis",
          "more": undefined,
          "days": [
            "2023-03-21",
            "2023-03-27",
            "2023-04-03",
            "2023-04-10",
            "2023-04-17",
            "2023-04-24",
            "2023-05-02",
            "2023-05-08",
            "2023-05-15",
            "2023-05-22",
            "2023-05-29",
            "2023-06-05",
            "2023-06-12",
            "2023-06-19",
            "2023-06-26",
            "2023-07-03",
            "2023-07-17",
            "2023-07-31",
            "2023-08-14",
            "2023-08-28",
            "2023-09-04",
            "2023-09-11",
            "2023-09-18",
            "2023-09-25",
            "2023-10-02",
            "2023-10-09",
            "2023-10-16",
            "2023-10-23",
            "2023-10-30",
            "2023-11-06",
            "2023-11-13"
          ]
        }
      },
      "Zone 2": {
        "divers": {
          "value": "Tous les Jeudis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques":  {
          "value": "Un jeudi sur 2",
          "more": undefined,
          "days": [
            "2023-01-05",
            "2023-01-19",
            "2023-02-02",
            "2023-02-16",
            "2023-03-02",
            "2023-03-16",
            "2023-03-30",
            "2023-04-13",
            "2023-04-27",
            "2023-05-11",
            "2023-05-25",
            "2023-06-08",
            "2023-06-22",
            "2023-07-06",
            "2023-07-20",
            "2023-08-03",
            "2023-08-17",
            "2023-08-31",
            "2023-09-14",
            "2023-09-28",
            "2023-10-12",
            "2023-10-26",
            "2023-11-09",
            "2023-11-23",
            "2023-12-07",
            "2023-12-21"
          ]
        },
        "verre": {
          "value": "Un jeudi sur 6",
          "more": undefined,
          "days": [
            "2023-02-02",
            "2023-03-16",
            "2023-04-27",
            "2023-06-08",
            "2023-07-20",
            "2023-08-31",
            "2023-10-12",
            "2023-11-23"
          ]
        },
        "vegetaux": {
          "value": "Les mardis",
          "more": undefined,
          "days": [
            "2023-03-22",
            "2023-03-28",
            "2023-04-04",
            "2023-04-11",
            "2023-04-18",
            "2023-04-25",
            "2023-05-03",
            "2023-05-09",
            "2023-05-16",
            "2023-05-23",
            "2023-05-30",
            "2023-06-06",
            "2023-06-13",
            "2023-06-20",
            "2023-06-27",
            "2023-07-04",
            "2023-07-18",
            "2023-08-01",
            "2023-08-15",
            "2023-08-29",
            "2023-09-05",
            "2023-09-12",
            "2023-09-19",
            "2023-09-26",
            "2023-10-03",
            "2023-10-10",
            "2023-10-17",
            "2023-10-24",
            "2023-10-31",
            "2023-11-07",
            "2023-11-14"
          ]
        }
      },
      "Collectif 1": {
        "divers": {
          "value": "Tous les Lundis et Jeudis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Tous les jeudis",
          "more": undefined,
          "days": "auto"
        },
        "verre": {
          "value": "Un mercredi sur 6",
          "more": undefined,
          "days": [
            "2023-02-01",
            "2023-03-15",
            "2023-04-26",
            "2023-06-07",
            "2023-07-19",
            "2023-08-30",
            "2023-10-11",
            "2023-11-22"
          ]
        },
        "vegetaux": {
          "value": "Les lundis",
          "more": undefined,
          "days": [
            "2023-03-21",
            "2023-03-27",
            "2023-04-03",
            "2023-04-10",
            "2023-04-17",
            "2023-04-24",
            "2023-05-02",
            "2023-05-08",
            "2023-05-15",
            "2023-05-22",
            "2023-05-29",
            "2023-06-05",
            "2023-06-12",
            "2023-06-19",
            "2023-06-26",
            "2023-07-03",
            "2023-07-17",
            "2023-07-31",
            "2023-08-14",
            "2023-08-28",
            "2023-09-04",
            "2023-09-11",
            "2023-09-18",
            "2023-09-25",
            "2023-10-02",
            "2023-10-09",
            "2023-10-16",
            "2023-10-23",
            "2023-10-30",
            "2023-11-06",
            "2023-11-13"
          ]
        }
      },
      "Collectif 2": {
        "divers":  {
          "value": "Tous les Lundis et Jeudis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Tous les jeudis",
          "more": undefined,
          "days": "auto"
        },
        "verre": {
          "value": "Un jeudi sur 6",
          "more": undefined,
          "days": [
            "2023-02-02",
            "2023-03-16",
            "2023-04-27",
            "2023-06-08",
            "2023-07-20",
            "2023-08-31",
            "2023-10-12",
            "2023-11-23"
          ]
        },
        "vegetaux": {
          "value": "Les mardis",
          "more": undefined,
          "days": [
            "2023-03-22",
            "2023-03-28",
            "2023-04-04",
            "2023-04-11",
            "2023-04-18",
            "2023-04-25",
            "2023-05-03",
            "2023-05-09",
            "2023-05-16",
            "2023-05-23",
            "2023-05-30",
            "2023-06-06",
            "2023-06-13",
            "2023-06-20",
            "2023-06-27",
            "2023-07-04",
            "2023-07-18",
            "2023-08-01",
            "2023-08-15",
            "2023-08-29",
            "2023-09-05",
            "2023-09-12",
            "2023-09-19",
            "2023-09-26",
            "2023-10-03",
            "2023-10-10",
            "2023-10-17",
            "2023-10-24",
            "2023-10-31",
            "2023-11-07",
            "2023-11-14"
          ]
        }
      }
    }
  };