
const weekdays = new Array(7);
weekdays[0] = "Dimanche";
weekdays[1] = "Lundi";
weekdays[2] = "Mardi";
weekdays[3] = "Mercredi";
weekdays[4] = "Jeudi";
weekdays[5] = "Vendredi";
weekdays[6] = "Samedi";

let S_Utils = {
  STORAGE_KEY_PREFIX : 'poubelles2',
  isLogDebug: process.env.REACT_APP_ENV !== 'production',
    
  getCurrentDate: function(){
    var currentDate = new Date();
    var currentDateInStr = currentDate.getFullYear() + '-' + ((currentDate.getMonth()+1) < 10 ? '0' : '') + (currentDate.getMonth()+1) + '-' + (currentDate.getDate() < 10 ? '0' : '') + currentDate.getDate() + 'T00:00:00Z';
    currentDate = new Date(currentDateInStr);
    return currentDate;
  },

  getNextDate: function(dateInStr: string){
    return new Date(dateInStr + 'T00:00:00Z');
  },

  formatDate: function(nextDate: Date){
    return weekdays[nextDate.getDay()] + ' ' + (nextDate.getDate() < 10 ? '0' : '') + nextDate.getDate() + '/' + ((nextDate.getMonth()+1) < 10 ? '0' : '') + (nextDate.getMonth()+1) + '/' + nextDate.getFullYear();
  },

  formatAlert: function(currentDate: Date, nextDate: Date){
    var ecartInDays = ((nextDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if(ecartInDays < 2)
      return 'imminent';
    if(ecartInDays <= 7)
      return 'soon';
    return '';
  },

  formatLabel: function(currentDate: Date, nextDate: Date){
    var ecartInDays = ((nextDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if(ecartInDays < 1)
      return 'Aujourd\'hui';
    if(ecartInDays < 2)
      return 'Demain';
    if(ecartInDays < 3)
      return 'Après-demain';
    if(ecartInDays < 4)
      return 'Dans 3 jours';
    if(ecartInDays < 5)
      return 'Dans 4 jours';
    if(ecartInDays < 6)
      return 'Dans 5 jours';
    if(ecartInDays < 7)
      return 'Dans 6 jours';
    if(ecartInDays < 14)
      return 'Dans + de 7 jours';
    return 'Y\'a le temps...';
  },

  getResetNext: function(){
    var newReset: any = {};
    return newReset;
  },

  getToday: function(){
    var currentDate = S_Utils.getCurrentDate();
    return S_Utils.formatDate(currentDate);
  }

  
};
export default S_Utils;