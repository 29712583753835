export const datas_leperrayenyvelines_2023 = {
    documents: [
      {  
        type: 'pdf', 
        path: 'assets/pdf/2023/le-perray-en-yvelines/2023.pdf', 
        name: 'Calendrier 2023'
      },
      { 
        type: 'pdf',
        path: 'assets/pdf/2023/le-perray-en-yvelines/2023-vegetaux.pdf', 
        name: 'Calendrier 2023 végétaux'
      },
      {  
        type: 'jpg', 
        path: 'assets/pdf/2023/le-perray-en-yvelines/2023-1.png', 
        name: 'Calendrier 2023'
      },
      {  
        type: 'jpg', 
        path: 'assets/pdf/2023/le-perray-en-yvelines/2023-2.png', 
        name: 'Zones 2023'
      },
      { 
        type: 'jpg',
        path: 'assets/pdf/2023/le-perray-en-yvelines/2023-vegetaux-1.jpg', 
        name: 'Calendrier 2023 végétaux 1'
      },
      { 
        type: 'jpg',
        path: 'assets/pdf/2023/le-perray-en-yvelines/2023-vegetaux-2.jpg', 
        name: 'Calendrier 2023 végétaux 2'
      }
    ],
    collect_days: {
      "Zone 1": {
        "divers": {
          "value": "Tous les Vendredis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Un lundi sur 2",
          "more": undefined,
          "days": [
            "2023-01_02",
            "2023-01-16",
            "2023-01-20",
            "2023-02-13",
            "2023-02-27",
            "2023-03-13",
            "2023-03-27",
            "2023-04-10",
            "2023-04-24",
            "2023-05-08",
            "2023-05-22",
            "2023-06-05",
            "2023-06-19",
            "2023-07-03",
            "2023-07-17",
            "2023-07-31",
            "2023-08-14",
            "2023-08-28",
            "2023-09-11",
            "2023-09-25",
            "2023-10-09",
            "2023-10-23",
            "2023-11-06",
            "2023-11-20",
            "2023-12-04",
            "2023-12-18"
          ]
        },
        "verre": {
          "value": "Un lundi sur 6",
          "more": undefined,
          "days": [
            "2023-01-23",
            "2023-03-06",
            "2023-04-17",
            "2023-05-29",
            "2023-07-10",
            "2023-08-21",
            "2023-10-02",
            "2023-11-13",
            "2023-12-23"
          ]
        },
        "vegetaux": {
          "value": "Les mercredis",
          "more": undefined,
          "days": [
            "2023-03-22",
            "2023-03-29",
            "2023-04-05",
            "2023-04-12",
            "2023-04-19",
            "2023-04-26",
            "2023-05-04",
            "2023-05-10",
            "2023-05-17",
            "2023-05-31",
            "2023-06-07",
            "2023-06-14",
            "2023-06-21",
            "2023-06-28",
            "2023-07-05",
            "2023-07-19",
            "2023-08-02",
            "2023-08-16",
            "2023-08-30",
            "2023-09-06",
            "2023-09-13",
            "2023-09-20",
            "2023-09-27",
            "2023-10-04",
            "2023-10-11",
            "2023-10-18",
            "2023-10-25",
            "2023-11-01",
            "2023-11-08",
            "2023-11-15"
          ]
        }
      },
      "Zone 2": {
        "divers": {
          "value": "Tous les Vendredis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques":  {
          "value": "Un lundi sur 2",
          "more": undefined,
          "days": [
            "2023-01_02",
            "2023-01-16",
            "2023-01-20",
            "2023-02-13",
            "2023-02-27",
            "2023-03-13",
            "2023-03-27",
            "2023-04-10",
            "2023-04-24",
            "2023-05-08",
            "2023-05-22",
            "2023-06-05",
            "2023-06-19",
            "2023-07-03",
            "2023-07-17",
            "2023-07-31",
            "2023-08-14",
            "2023-08-28",
            "2023-09-11",
            "2023-09-25",
            "2023-10-09",
            "2023-10-23",
            "2023-11-06",
            "2023-11-20",
            "2023-12-04",
            "2023-12-18"
          ]
        },
        "verre": {
          "value": "Un mardi sur 6",
          "more": undefined,
          "days": [
            "2023-01-24",
            "2023-03-07",
            "2023-04-18",
            "2023-05-30",
            "2023-07-11",
            "2023-08-22",
            "2023-10-03",
            "2023-11-14",
            "2023-12-26"
          ]
        },
        "vegetaux": {
          "value": "Les jeudis",
          "more": undefined,
          "days": [
            "2023-03-23",
            "2023-03-30",
            "2023-04-06",
            "2023-04-13",
            "2023-04-20",
            "2023-04-27",
            "2023-05-05",
            "2023-05-11",
            "2023-05-18",
            "2023-05-25",
            "2023-06-01",
            "2023-06-08",
            "2023-06-15",
            "2023-06-22",
            "2023-06-29",
            "2023-07-06",
            "2023-07-20",
            "2023-08-03",
            "2023-08-17",
            "2023-08-21",
            "2023-09-07",
            "2023-09-14",
            "2023-09-21",
            "2023-09-28",
            "2023-10-05",
            "2023-10-12",
            "2023-10-19",
            "2023-10-26",
            "2023-11-02",
            "2023-11-09",
            "2023-11-16"
          ]
        }
      },
      "Collectif 1": {
        "divers": {
          "value": "Tous les Mardis et Vendredis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Tous les lundis",
          "more": undefined,
          "days": [
            "2023-01-02",
            "2023-01-09",
            "2023-01-16",
            "2023-01-23",
            "2023-01-30",
            "2023-02-06",
            "2023-02-13",
            "2023-02-20",
            "2023-02-27",
            "2023-03-06",
            "2023-03-13",
            "2023-03-20",
            "2023-03-27",
            "2023-04-03",
            "2023-04-10",
            "2023-04-17",
            "2023-04-24",
            "2023-04-29",
            "2023-05-08",
            "2023-05-15",
            "2023-05-22",
            "2023-05-29",
            "2023-06-05",
            "2023-06-12",
            "2023-06-19",
            "2023-06-26",
            "2023-07-03",
            "2023-07-10",
            "2023-07-17",
            "2023-07-24",
            "2023-07-31",
            "2023-08-07",
            "2023-08-14",
            "2023-08-21",
            "2023-08-28",
            "2023-09-04",
            "2023-09-11",
            "2023-09-18",
            "2023-09-25",
            "2023-10-02",
            "2023-10-09",
            "2023-10-16",
            "2023-10-23",
            "2023-10-30",
            "2023-11-06",
            "2023-11-13",
            "2023-11-20",
            "2023-11-27",
            "2023-12-04",
            "2023-12-11",
            "2023-12-18",
            "2023-12-23"
          ]
        },
        "verre": {
          "value": "Un lundi sur 6",
          "more": undefined,
          "days": [
            "2023-01-23",
            "2023-03-06",
            "2023-04-17",
            "2023-05-29",
            "2023-07-10",
            "2023-08-21",
            "2023-10-02",
            "2023-11-13",
            "2023-12-23"
          ]
        },
        "vegetaux": {
          "value": "Les mercredis",
          "more": undefined,
          "days": [
            "2023-03-22",
            "2023-03-29",
            "2023-04-05",
            "2023-04-12",
            "2023-04-19",
            "2023-04-26",
            "2023-05-04",
            "2023-05-10",
            "2023-05-17",
            "2023-05-31",
            "2023-06-07",
            "2023-06-14",
            "2023-06-21",
            "2023-06-28",
            "2023-07-05",
            "2023-07-19",
            "2023-08-02",
            "2023-08-16",
            "2023-08-30",
            "2023-09-06",
            "2023-09-13",
            "2023-09-20",
            "2023-09-27",
            "2023-10-04",
            "2023-10-11",
            "2023-10-18",
            "2023-10-25",
            "2023-11-01",
            "2023-11-08",
            "2023-11-15"
          ]
        }
      },
      "Collectif 2": {
        "divers":  {
          "value": "Tous les Mardis et Vendredis matin",
          "more": undefined,
          "days": "auto"
        },
        "plastiques": {
          "value": "Tous les lundis",
          "more": undefined,
          "days": [
            "2023-01-02",
            "2023-01-09",
            "2023-01-16",
            "2023-01-23",
            "2023-01-30",
            "2023-02-06",
            "2023-02-13",
            "2023-02-20",
            "2023-02-27",
            "2023-03-06",
            "2023-03-13",
            "2023-03-20",
            "2023-03-27",
            "2023-04-03",
            "2023-04-10",
            "2023-04-17",
            "2023-04-24",
            "2023-04-29",
            "2023-05-08",
            "2023-05-15",
            "2023-05-22",
            "2023-05-29",
            "2023-06-05",
            "2023-06-12",
            "2023-06-19",
            "2023-06-26",
            "2023-07-03",
            "2023-07-10",
            "2023-07-17",
            "2023-07-24",
            "2023-07-31",
            "2023-08-07",
            "2023-08-14",
            "2023-08-21",
            "2023-08-28",
            "2023-09-04",
            "2023-09-11",
            "2023-09-18",
            "2023-09-25",
            "2023-10-02",
            "2023-10-09",
            "2023-10-16",
            "2023-10-23",
            "2023-10-30",
            "2023-11-06",
            "2023-11-13",
            "2023-11-20",
            "2023-11-27",
            "2023-12-04",
            "2023-12-11",
            "2023-12-18",
            "2023-12-23"
          ]
        },
        "verre": {
          "value": "Un mardi sur 6",
          "more": undefined,
          "days": [
            "2023-01-24",
            "2023-03-07",
            "2023-04-18",
            "2023-05-30",
            "2023-07-11",
            "2023-08-22",
            "2023-10-03",
            "2023-11-14",
            "2023-12-26"
          ]
        },
        "vegetaux": {
          "value": "Les jeudis",
          "more": undefined,
          "days": [
            "2023-03-23",
            "2023-03-30",
            "2023-04-06",
            "2023-04-13",
            "2023-04-20",
            "2023-04-27",
            "2023-05-05",
            "2023-05-11",
            "2023-05-18",
            "2023-05-25",
            "2023-06-01",
            "2023-06-08",
            "2023-06-15",
            "2023-06-22",
            "2023-06-29",
            "2023-07-06",
            "2023-07-20",
            "2023-08-03",
            "2023-08-17",
            "2023-08-21",
            "2023-09-07",
            "2023-09-14",
            "2023-09-21",
            "2023-09-28",
            "2023-10-05",
            "2023-10-12",
            "2023-10-19",
            "2023-10-26",
            "2023-11-02",
            "2023-11-09",
            "2023-11-16"
          ]
        }
      }
    }
  };